@import url('../../../assets/styles/variables.css');

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}


.modal h2 {
  margin-top: 0;
}

.modal label {
  display: block;
  margin-top: 10px;
}

.modal input[type="email"]
 {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modalBackground .modal button {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  background-color: var(--dash-sidebar-secondary);
  color: white;
  cursor: pointer;
  width: 100%;
  opacity: 1;
}

.modalTop {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.modalTop > div {
  width: 45%;
}

.successMessage {
  color: var(--success-color);
  font-size: 10px;
  margin-top: 10px;
}

.errorMessage {
  color: var(--new-dashboard-red);
  font-size: 10px;
  margin-top: 10px;
}

.modalContent textarea {
  display: block;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px);
}
