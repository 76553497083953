@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  justify-content: space-between;
  height: 90px;
  background: var(--white-color);
  padding: 30px 35px 0 45px;
}

.wrapper * {
  font-family: var(--primary-font);
}

.profile {
  margin-right: 20px;
}

.left {
  display: flex;
  align-items: cennter;
}

.profile img {
  max-width: 50px;
}

.wrapper h2 {
  color: var(--dash-sidebar-primary);
  font-size: 32px;
  font-weight: bold;
  margin: 5px 0 5px 0;
}

.wrapper h3 {
  color: var(--dash-sidebar-primary);
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.wrapper h4 {
  color: var(--dash-sidebar-primary);
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}