@import url('../../assets/styles/variables.css');

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #49B805;
  border-radius: 7px;
  color: var(--white-color);
  font-size: 0.9em;
  padding: 8px 0 14px 0;
  opacity: 0;
  max-width: 300px;
  margin: 0 auto;
}

.show {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

.alert img {
  display: block;
  margin: 10px;
}


@keyframes fadeIn {
  0% {
    opacity:0;
  }
  30% {
    opacity:1;
  }
}
