@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--portal-secondary-color);;
}

.wrapper * {
  font-family: var(--primary-font);
}

.wrapper img {
  padding: 30px 20px 0 20px;
}

.wrapper .copy { 
  color: #fff;
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.wrapper .companyLogo {
  max-width: 200px;
}

/* Whitelabel */
.wrapperWhitelabel {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--portal-secondary-color);;
  width: 100%;
  padding: 30px 0;
}

.wrapperWhitelabel p {
  font-size: 12px;
  margin-bottom: -5px;
  margin-left: 5px;
  color: var(--white-color);
}

.wrapperWhitelabel div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  padding: 0 20px;
}

.wrapperWhitelabel div:nth-child(2) {
  border-left: 1px solid var(--white-color);
}