@import url('../../assets/styles/variables.css');

.wrapper {
}

.wrapper h4 {
  color: var(--dash-sidebar-secondary);
  font-size: 15px;
  font-weight: 700;
}

.wrapper h3 {
  color: var(--dash-sidebar-secondary);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.spanContent {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.spanContent span {
  color: var(--dash-sidebar-secondary);
  font-size: 15px;
}

.spanContent span span {
  display: inline-block !important;
}

.spanContent b {
  color: var(--portal-dark-gray-color);
}

.spanContent i {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 16px;
  height: 16px;
}

.spanContent svg {
  fill: var(--portal-dark-gray-color);
  width: 16px;
  height: 16px;
}

.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.actions > button {
  margin-right: 10px;
}