@import url('../../assets/styles/variables.css');

/* styles.module.css */

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;
  border: 1px solid var(--portal-gray-color);
  transition: border-color 0.3s ease;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
}

.containerChecked {
  border-color: var(--portal-primary-color);
  font-weight: 700;
}

.radio {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  border: 1px solid var(--portal-gray-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.radioChecked {
  background-color: var(--portal-primary-color);
  color: #fff;
  border-color: var(--portal-primary-color);
}

.radioUnchecked {
  background-color: #fff;
}

.labelChecked {
  color: var(--portal-primary-color);
}

.labelUnchecked {
  color: var(--portal-gray-color);
}
