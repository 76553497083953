@import url('../../assets/styles/variables.css');

.wrapper {
}

.rows span {
  display: block;
  padding: 15px 40px;
  border-bottom: 1px solid var(--portal-gray-color);
  color: var(--portal-dark-gray-color);
  font-size: 11px;
  font-weight: 500;
}

.rows span:last-of-type {
  border-bottom: 0px;
}