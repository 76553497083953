@import url('../../assets/styles/variables.css');

.wrapper {
  width: 100%;
}

.wrapper tr th {
  text-align: left;
  color: var(--portal-dark-gray-color);
  font-size: 13px;
  font-weight: 600;
}

.wrapper thead {
  border-bottom: 1px solid var(--portal-gray-color);
  padding: 10px;
}

.wrapper tbody tr {
  padding: 8px 0;
}

.wrapper tbody tr td {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: var(--dash-sidebar-secondary);
  padding: 8px;
}

.wrapper img {
  cursor: pointer;
  margin-left: 16px;
}

.wrapper thead tr {
  display: grid;
  grid-template-columns: var(--columns);
}

.wrapper tbody tr {
  display: grid;
  grid-template-columns: var(--columns);
}