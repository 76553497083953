@import url('../../../assets/styles/variables.css');

.offerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  background: var(--portal-ui-gray);
  margin-bottom: 40px;
}

.offerContainer * {
  font-family: var(--primary-font);
}

.top {
  display: flex;;
  justify-content: center;
  width: 100%;
  padding: 20px;
};

.topContent {
  display: flex;
  justify-content: center;
  margin: 40px 20px 0 20px;
  width: 100%;
}

.topContent h1 {  
  color: var(--portal-ui-dark);
  font-size: 20px;
  font-weight: normal;
  margin: 0 20px;
}

.topContent h1 span {  
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 500;
}

.bigCard {
  width: calc(100% - 40px);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: var(--white-color);
}

.headerCard {
  display: flex;
  justify-content: center;;
  border-bottom: 1px solid var(--portal-ui-gray);
}

.headerCard h4 {
  font-size: 12px;
  font-weight: normal;
}

.headerCard h4 span {
  font-size: 12px;
  font-weight: normal;
  color: var(--portal-secondary-color);
}

.topCard {
  display: flex;
  padding: 10px 20px;
}

.topCard .topCardImage img {
  max-width: 80px;
}

.topCardProductInfo .topCardProductInfoItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}

.topCardProductInfoItem {
  display: flex;
}

.topCardProductInfoItem p {
  display: flex;
  font-size: 8px;
  margin: 10px 0 0 0;
}

.topCardProductInfoItem h5 {
  text-align: left;
  font-size: 8px;
  font-weight: 500;
  color: var(--portal-secondary-color);
  margin: 0  0 0 5px;
}

.topCardRow {
  display: flex;
  justify-content: space-between;
  background: var(--portal-ui-gray);
  padding: 10px 20px;
}

.topCardRow .values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topCardRow span {
  font-size: 8px;
  margin-bottom: 5px;
}

.topCardRow h6 {
  font-size: 10px;
  font-weight: normal;
  text-decoration: line-through;
  margin: 0;
}

.topCardRow .offValue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topCardRow .offValue span {
  border-radius: 10px;
  color: var(--primary-color);
  background-color: rgba(33, 155, 228, 0.1);
  padding: 2px 5px;
}

.topCardRow .offValue h6 {  
  color: var(--primary-color);
  font-weight: bold;
  text-decoration: none;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.options .info {
  display: flex;
  align-items: center;
  padding: 20px;
}

.options .info img {
  width: 30px;
}

.options .currencyContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.info span {
 text-align: left;
}

.info h5 {
  margin: 0;
  font-size: 20px;
  color: var(--portal-secondary-color);
  margin-top: 5px;
  text-align: left;
}

.info h6 {
  margin: 0;
  font-family: var(--primary-font) !important;
  color: var(--portal-secondary-color);
  font-weight: 700;
  font-size: 14px;
  line-height: 24px
}

.options h1 {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.options .container .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: none;
}

.options .container span {
 transform: none;
}

.options .container h5 {
  margin: 0;
  font-size: 20px;
  color: var(--portal-secondary-color);
  margin-top: 5px;
}

.options .container h6 {
  margin: 0;
  font-family: var(--primary-font) !important;
  color: var(--portal-secondary-color);
  font-weight: 700;
  font-size: 12px;
  line-height: 24px
}

.installmentsContainer {
  border-radius: 8px;
  text-align: left;
  background: var(--portal-ui-gray);
  padding: 15px;
}

.installmentsContainer h2 {
  font-size: 16px;
  color: var(--portal-secondary-color);
  text-align: left;
  background: var(--portal-ui-gray);;
}

.cardBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
}

.warningMessage {
  display: flex;
  font-size: 10px;
  padding: 20px;
  text-align: left;
  background: #FFF5E1;
  margin: 15px 20px;
}

.warningMessage span {
  margin-right: 10px;
}

.checkboxInputContainer {
  display: flex;
  align-items: center;
  padding: 20px;
  text-align: left;
}

.checkboxInputContainer input {
  margin-right: 10px;
  width: 20px;
}

.checkboxInputContainer label {
 font-size: 8px;
}





/* ************* */

.top .goBackButton {
  width: 20px;
  margin-top: 50px;
}

.customImage {
  background-image: url('../../../assets/images/ellipse3.svg');
  background-repeat: no-repeat;
  background-position: center;
  min-width: 100px;
}

.offerCard {
  margin-top: 40px;
  background-color: var(--white-color);
  padding: 0 35px;
}

.offerCard h1 {
  display: flex;
  width: 100%;
}

.offerInfo {
  display: flex;
  justify-content: space-between;
}

.offerInfo > div h1 {
  color: var(--portal-secondary-color);
}

.offerInfo img {
  width: 150px;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container .left {
  font-family: var(--primary-font) !important;  
  width: 65%;
  color: var(--secondary-primary-color);
}

.container .left span {
  font-family: var(--primary-font) !important;  
  color: var(--secondary-primary-color);
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
}

.container .right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 35%;
}

.container .right span {  
  color: var(--secondary-primary-color);
  font-family: var(--primary-font);
  font-size: 15px;
  font-weight: 700;
  text-align: right;
}


.offerInfoCount {
  background-color: var(--portal-ui-gray);
  border-radius: 15px;
  padding: 5px;
}


.amountDebt {
  color: var(--red-color);
  font-weight: bold;
}

.amountDebtDiscount {
  color: var(--dark-green);
  font-weight: bold;
}

.offerCard > * {
  margin-bottom: 30px;
}

.countdown {
  margin: 0 auto;
  color: var(--portal-ui-gray);
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  max-width: 280px;
}

.offerCard label:last-of-type > div {
  border: none;
}

.linkButton {
  color: var(--primary-color);
  font-size: 15px;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  line-height: 20px;
  text-underline-offset: 3px;
  transition: color 0.3s ease, text-decoration-color 0.3s ease;
}

.linkButton:hover {
  color: var( --new-dashboard-primary);
}

.moreInfoContainer {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
  margin-top: 35px;
  border-radius: 5px;
  background: rgba(51, 69, 136, 0.05);
  padding: 15px;
  text-align: left;
}

.moreInfoVisible {
  opacity: 1;
  height: auto;
}

.moreInfoContainer h4 {
  color: var(--new-dashboard-primary);
  font-size: 13px;
  font-weight: 600;
  line-height: 28px;
}

.moreInfoContainer span {
  color: var(--black-color);
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  text-transform: lowercase;
}

.opacityLow {
  opacity: 0.3;
}

@media only screen and (min-width: 1100px) {
  .offerContainer {
    max-width: 100%;
  }
}
