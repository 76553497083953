@import url('../../assets/styles/variables.css');


.wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  width: 375px;
  height: 60px;
  color: var(--dash-sidebar-secondary);
  font-size: 15px;
  font-weight: 700;
  box-sizing: border-box;
  z-index: 999;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: var(--portal-primary-color);
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.iconContainer.errorIcon svg {
  fill: red;
}

.iconContainer svg {
  width: 25px;
  height: 25px;
}

.content {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 20px;
}

.message {
  margin-left: 10px;
}

.close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: var(--portal-secondary-light-color);
}

.close svg {
  fill: var(--portal-gray-color);
  width: 30px;
  height: 30px;
}
