@import url('../../assets/styles/variables.css');


.datePicker {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  height: 45px !important;
  box-sizing: border-box !important;

  border-radius: 5px !important;
  border: 1px solid var( --new-dashboard-gray) !important;
  padding: 15px 20px !important;
  font-size: 15px !important;
  color: var( --new-dashboard-gray) !important;

  text-align: center !important;
}

.popper {
  width: 259px !important;
  height: 259px !important;
  text-align: center !important;
}

.popper .react-datepicker__day-names {
  display: none !important;  
}