.wrapper {
  display: flex;
  align-items: center; /* Alinha o conteúdo verticalmente */
  justify-content: space-between; /* Distribui o conteúdo horizontalmente */
  width: 130px;
  height: 35px;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 10px;  
  padding: 3px 10px; /* Aumenta o padding horizontal para melhor espaçamento */
  border: 1px solid #666;
  cursor: pointer;
}

.wrapper::after {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 0;
  padding: 0;
  background: var(--portal-secondary-light-color);
  pointer-events: none;
  transition: 0.25s all ease;
  color: var(--portal-dark-gray-color);
  font-size: 15px;
  transition: transform 0.25s ease;
  z-index: 9999;
}

.datepickerWrapper {
  z-index: 10000;
  position: absolute;
  top: 40px;
  left: 0; /* Certifica-se de que o seletor esteja alinhado à esquerda */
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 280px; /* Define uma largura consistente */
}

.dateButton {
  width: 100%;
  border: none;
  background-color: #fff;
  cursor: pointer;
  text-align: left; /* Alinha o texto à esquerda */
  padding-left: 10px; /* Aumenta o espaçamento interno do botão */
}

.wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: 0;
  border: none;
  background-color: transparent;
  color: var(--portal-dark-gray-color);
  padding: 5px 18px;
  height: 35px;
}

.wrapper select option {
  display: block;
  padding: 10px 5px; /* Ajuste para espaçamento vertical e horizontal entre os meses */
  cursor: pointer;
}

.wrapper.inputStyle select {
  background-color: #fff;
  padding: 5px; /* Mantém o espaçamento interno no seletor */
}
