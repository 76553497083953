@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
:root {
  --portal-logo: url('https://d3i2d7rykfq6oq.cloudfront.net/partners%2Flogo%2Fkollectaio.svg');
  --athens-gray-color: #DFE0EB;
  --black-color: #000000;
  --bright-turquoise-color: #13CAE6;
  --bunting-color: #11183F;
  --catskill-white-color: #EEF2F6;
  --gray-90-color: #e5e5e5;
  --emerald-color: #4ECB71;
  --indigo-color: #5668D1;
  --red-color: #FC1100;
  --white-color: #FFFFFF;
  --waterloo-color: #757A96;
  --primary-color: #219BE4; 
  --secondary-color: #88F4FF;
  --success-color: #2BC055;
  --dark-green: #2C5D59;
  --light-gray: #cccccc;
  --dark-gray: #43434E;

  --portal-primary-color: #219BE4;
  --portal-secondary-color: #635AD9;
  --portal-tertiary-color: #7338AC;

  --portal-secondary-color: #334588;

  --portal-gray-color: #B9B9B9;
  --portal-dark-gray-color: #828282;
  --portal-dark-gray-color-secondary: #b9b9b9;

  --portal-primary-light-color: #88F4FF;
  --portal-secondary-light-color: #F5F5F5;

  --primary-font: 'Esphimere', sans-serif;
  --secondary-font: 'Roboto', sans-serif;

  --dashboard-brown-font: #7D3300;
  --dashboard-brown-background: #FDE3D1;
  --dashboard-green-font: #2F7D00;
  --dashboard-green-background: #E2FDD1;
  --dashboard-blue-font: #00577D;
  --dashboard-blue-background: #D1E3FD;
  --dashboard-yellow-font: #7D3300;
  --dashboard-yellow-background: #FDE3D1;
  --dashboard-red-font: #7D0000;
  --dashboard-red-background: #FDD4D1;

  --dash-sidebar-primary: #1B2752;
  --dash-sidebar-secondary: #120638;
  --dash-sidebar-tertiary: #376499;
  --dash-green-color: #E4FDD1;
  --dash-green-dark-color: #8AA377;

  --new-dashboard-primary: #334588;
  --new-dashboard-secondary: #120638;
  --new-dashboard-gray: #666;
  --new-dashboard-gray-light: #CDCDCD;
  --new-dashboard-green: #08B984;
  --new-dashboard-lilac: #7300CF;
  --new-dashboard-blue: #03B4DB;
  --new-dashboard-orange: #FF5C00;
  --new-dashboard-yellow: #FF9900;
  --new-dashboard-red: #DF0000;
  --new-dashboard-gray-dark: #828282;

  --new-dashboard-primary-font: 'Esphimere';

  --portal-ui-dark: #222;
  --portal-ui-gray: #f6f6f6;


  /* Portal - Header */
  --portal-header-primary: #334588;
  --portal-header-secondary: #120638;

  --size-xxl: 60px;
  --size-xl: 50px;
  --size-large: 38px;
  --size-medium: 22px;
  --size-small: 14px;
}
