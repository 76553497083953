@import url('../../../assets/styles/variables.css');

.container {
  padding: 0 45px;
}

.containerTop h3 {
  margin-top: 40px;
}

.filtersContainer {
  display: flex;
  flex-direction: column; 

  border: 1px solid #CDCDCD;
  border-radius: 8px;
  padding: 20px;
  margin: 0 45px;
}

.pastDuesContainer {
  border: 1px solid #CDCDCD;
  border-radius: 8px;
  padding: 20px;
  margin: 0 45px;
}

.filtersContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.filtersContent > * {
  flex-grow: 1;
  margin-right: 15px;
}

.subContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 350px;
}

.subContainer > * {
  margin-right: 10px;
}

.generalButton button {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: var(--portal-secondary-color);
  margin: 20px 10px 0 10px;
  cursor: pointer;
  color: #fff;
  min-width: auto !important;
}

.pastDuesContainer {
  margin-top: 50px;
}

.pastDuesContainer h3 {
  margin-bottom: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.empty img {
  width: 150px;
  height: 150px;
  margin-left: 50px;
}

.empty h3 {
  font-size: 20px;
  color: var(--new-dashboard-primary);
}

.empty p {
  text-align: center;
  max-width: 234px;
  font-size: 12px;
}

.close {
  background-color: transparent;
  outline: none;
  width: 10px;
  height: 10px;
  border: none;
  right: calc(-100% + 30px);
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 20px;
  padding: 11px;
}

.close::after,
.close::before {
  content: ' ';
  position: absolute;
  width: 1.5px;
  height: 10px;
  background-color: #000;
}

.close::after {
  transform: rotate(-45deg);
}

.close::before {
  transform: rotate(45deg);
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  background-color: #FCFCFC;
}

.content header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  height: 110px;
  background-color: #fff;
  color: #1B2752;
}

.content header h1 {
  font-family: Esphimere;
  font-weight: bold;
  font-size: 32px;
}

.content header button {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.content header button span {
  font-family: Esphimere;
  font-weight: 600;
  font-size: 12px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 22px 50px 4px;
}

.action-buttons button {
  height: 40px;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: Esphimere;
  font-weight: bold;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background-color: #1B2752;
  color: #fff;
  max-width: 200px;
  min-width: auto;
}

.primary {
  background-color: #1B2752;
  color: #fff;
}

.secondary {
  background-color: #DFE6FE;
  color: #334588;
}

.outlined {
  border: 1px solid #1B2752;
}

.tab-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;  
  margin-top: 50px;
}

.tab-group {
  padding: 0 50px;
  border-bottom: 1px solid #ddd;
}

.tab-item {
  padding: 0 26px 14px 12px;
  position: relative;
  display: inline-block;
}

.tab-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #5D3FD3;
}

.tab-item span {
  font-family: Esphimere;
  font-weight: 600;
  font-size: 16px;
  color: #1B2752;
}

.content main {
  display: flex;
  flex-direction: column;
  gap: 55px;
}

.highlight-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
  padding: 0 50px;
}

.highlight-card {
  flex: 1 1 calc(25% - 32px);
  display: flex;
  align-items: center;
  padding: 12px 28px;
  min-width: 300px;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #E3F5FF;
}

@media (max-width: 1200px) {
  .highlight-card {
    flex: 1 1 calc(50% - 32px);
  }
}

@media (max-width: 800px) {
  .highlight-card {
    flex: 1 1 calc(100% - 32px);
  }
}

.highlight-card img {
  width: 40px;
  height: 40px;
}

.highlight-card-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.highlight-card-info span {
  font-family: Esphimere;
  font-weight: bold;
  font-size: 10px;
  color: #000;
}

.highlight-card-info strong {
  font-family: Esphimere;
  font-weight: 600;
  font-size: 28px;
  color: #333;
}

.charts {
  display: flex;
  gap: 24px;
  padding: 0 50px;
}

.charts > * {
  flex: 1;
}

/* Adicionando estilos do layout funcional */
.card, .cardGreen {
  font-family: var(--new-dashboard-primary-font);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin: 15px;
  padding: 25px 0;
}

.card > *, .cardGreen > * {
  margin: 0;
  padding: 0;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.card h4 {
  font-family: var(--new-dashboard-primary-font);
  margin: 0;
}

.card h2 {
  font-family: var(--new-dashboard-primary-font);
  margin: 0;
  font-size: 32px;
}

.card i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  background: #F0F0F0;
  border-radius: 8px;
  margin-right: 20px;
}

.card i svg {
  width: 24px !important;
  height: 24px !important;
}

.amountTotal {
  font-size: 30px;
  padding-top: 5px;
  color: #11183F;
}

.amountTotalGreen {
  padding-top: 5px;
  color: #03942C;
}

.containerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.containerFilters {
  display: flex;
  justify-content: flex-end;
}

.generalButton button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 90px;
  max-width: 150px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px 10px;
  border: none;
  height: auto;
  background: var(--portal-secondary-color);
  margin: 0 10px 0 10px;
}

@media (max-width: 1700px) {
  .charts {
    flex-wrap: wrap;
  }
  .card, .cardGreen {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .card > div {
    margin-top: 10px;
    flex-direction: column;
  }
}

