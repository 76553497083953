@import url('../../../../assets/styles/variables.css');

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 21px;
  border-radius: 48px;
  padding: 8px 12px;
}

.wrapper {
  font-size: 15px;
}

.wrapper span {
  margin-right: 10px;
}

.wrapper.arrowUp {
  background: #EDF9E7;
  color: #2E5317;
}

.wrapper.arrowDown {
  background: #FDD4D1;
  color: #532517;
}

.wrapper.arrowStraight {
  background: #F9F1E7;
  color: #533B17;
}