@import url('../../assets/styles/variables.css');

.wrapper {
  width: 190px;
  height: 35px;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 10px;
}

.wrapper::after {
  content: "\25BC";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 0;
  padding: 0;
  background: #fff;
  pointer-events: none;
  transition: 0.25s all ease;
  color: var(--new-dashboard-gray);
  font-size: 13px;
  transition: transform 0.25s ease;
  height: 4px;
}

.wrapper.active::after {
  content: "\25B2";
}

.wrapper:hover::after {
  color: var(--new-dashboard-gray);
}

.wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: 0;
  border: none;
  background-color: transparent;
  color: var(--new-dashboard-gray);
  overflow: auto;
  border-radius: 5px;
  padding: 5px 18px;
  height: 35px;
  
  border: 1px solid var(--new-dashboard-gray);
}

.wrapper select::-ms-expand {
  display: none;
}

.wrapper select option {
  display: block;
  padding: 20px 18px;
  cursor: pointer;
}

.wrapper.inputStyle {
  transform: translateY(5px);
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--portal-dark-gray-color);
  font-size: 12px;
  color: var(--portal-dark-gray-color);
  padding: 5px 0;
}

.wrapper.inputStyle select {
  background-color: #fff;  
}

.wrapper.inputStyle::after {
  background-color: #fff;
  top: 15px;
}

.intervalPickerWrapper {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.dateContainer label {
  font-size: 10px;
  font-weight: normal;
}

.searchButton {
  background-color: var(--new-dashboard-primary);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 35px;
  height: 35px;
}

.searchIcon {
  font-size: 16px;
  margin-right: 5px;
}

.datesModalContainer {
  width: 280px;
  padding: 15px;
  border-radius: 4px;
  background: var(--white-color);
  transform: translate(-120px, 10px);
  border: 1px solid var(--new-dashboard-primary);
}

.specificPickerWrapper .react-datepicker-wrapper {
  width: 100%;
}


