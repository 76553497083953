.btnUpload {
  border: none;
  background: #11183F;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 0.7em;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
}

.uploadFileContainer {
  display: flex;
  width: 100%;
}

.btnUpload:hover {
  opacity: 0.9;
}

.inputFileTrigger {
  text-align: center;
  display: block;
  padding: 14px 45px;
  background: #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  color: #757A96;
  font-size: 0.8em;
  transition: all .4s;
  cursor: pointer;
  width: 100%;
}

.inputFile {
  position: absolute;
  top: -62px;
  left: 0;
  width: 50%;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}

.inputIcon {
  position: relative;
  top: 10px;
}

.inputListFiles {
  width: 100%;
}

.triggerIcon {
  display: block;
  margin-bottom: 40px;
}

.contentInputFile {
  width: 100%;
}

.inputActions {
  text-align: center;
}

.fileItens {
  padding: 0 40px;
}


.itemNameFile,
.itemSizeFile {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  color: #757A96;
  letter-spacing: 0.2px;
  margin: 0;
  padding: 0;
}

.itemNameFile {
  font-size: 0.9em;
  line-height: 20px;
}

.itemSizeFile {
  font-size: 0.7em;
}

.fileItem {
  padding: 5px;
  display: flex;
}

.avatarFile {
  text-align: center;
  letter-spacing: 0.2px;
  color: #757A96;
  border: 1px solid #11183F;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.8em;
  border-radius: 50%;
  padding: 10px 6px;
  margin-right: 10px;
}



.modalContainer {
  height: 200px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}


.modalContainer p {
  margin-bottom: 40px;

  color: #11183F;
}

.modalContainer span {
  font-weight: bold;
}

.modalContainer img {
  height: 70px;
  /* width: 30px; */
}

.btnSubmit {
  border: none;
  background: #11183F;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 0.7em;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
}

.btnSubmit:hover {
  opacity: 0.9;
}