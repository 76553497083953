@import url('../../../assets/styles/variables.css');

.loginContainer {
  background-image: url('background.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer * {
  font-family: var(--primary-font);
}

.left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left img {
  max-width: 250px;
}

.right {
  width: 50%;
}

.cardForm {
  background: var(--white-color);
  border: 1px solid var(--athens-gray-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 5px 20px 40px 40px;
  width: 600px;
  height: 550px;
  padding-bottom: 10px;
}

.formGroup {
  margin: 0;
}

.formItem>* {
  display: flex;
}

label {
  font-family: var(--primary-font);
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #4B4B4B;
  width: 100%;
}

input {
  margin-top: 5px;
  background: #FCFDFE;
  border: 1px solid #F0F1F7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  margin-top: 15px;
  border: 1px solid #666666;
}

.cardFormHeader {
  text-align: center;
}

.cardFormHeader img {
  max-width: 215px;
}

.textInfo {
  font-family: var(--primary-font);
  font-size: 35px;
  color: #1B2752;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}

.cardFormHeader p {
  font-size: 18px;
  text-align: left;
  color: #4B4B4B;
  margin: 0 0 35px 0;
}

.btnSubmit {
  width: 90%;
  border: none;
  background: #1B2752;
  box-shadow: 0px 4px 12px rgba(86, 104, 209, 0.5);
  border-radius: 8px;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
}

.btnSubmit:hover {
  opacity: 0.9;
}

.errorMessage {
  color: #f04c4c;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.lockMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  color: var(--success-color);
  text-align: center;
  padding: 10px 0;
}

.lockMessage i {
  margin-right: 5px;
}

.lockMessage i  svg {
  width: 20px;
  height: 20px;
}

.passContainer {
  display: flex;
  align-items: center;
}

.passType {
  background: none;
  border: none;
  transform: translate(-65px, 70px);
  cursor: pointer;
  z-index: 5;
  line-height: 0; 
  margin-left: auto;
  margin-right: 0;
}

.passType svg {
  margin-top: 5px;
  width: 15px;
  height: 15px;
  fill: var(--waterloo-color);
}

.inputsContainer {
  margin-bottom: 45px;
}

@media (max-width: 768px) {
  .loginContainer {
    flex-direction: column;
  }

  .left, .right {
    width: 100%;
  }

  .left {
    margin-bottom: 20px;
    justify-content: center;
  }

  .cardForm {
    width: 90%;
    margin: 0 auto;
  }

  .btnSubmit {
    width: 100%;
  }
}