@import url('../../../assets/styles/variables.css');

.container {
  background: #f8f8f8;
}

.container * {
  font-family: var(--primary-font);
}

.containerTop {
  display: flex;
  flex-direction: column;
  background: var(--white-color);
  margin: 40px 35px 0 45px;
}

.containerContent {  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f8f8f8;
}

.contentBottom {
  display: flex;
  gap: 40px;
  margin-top: 40px;
}

.contentBottom > div {
  width: 50%;
}

.topCard {
  display: flex;
  background: var(--white-color);
  padding: 30px 50px;
  border-radius: 8px;
  margin: 30px 20px 15px 20px;
  border: 1px solid var(--portal-gray-color);
}

.topCardLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.containerLeft {
  margin-right: 20px;
}

.containerLeft h2 {
  font-size: 25px;
  font-weight: 700;
  color: #1B2752;
  margin-bottom: 10px;
  margin-top: 0;
}

.containerLeft h3 {
  font-size: 16px;
  font-weight: 700;
  color: var(--dash-sidebar-secondary);
  margin-bottom: 12px;
}

.topCardLeft .topCardLeftTitle  {
  font-size: 20px;
  font-weight: 700;
  color: var(--dash-sidebar-secondary);
  margin-bottom: 15px;
}

.topCardLeftInfo {
  display: flex;
}

.topCardLeftItem {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--portal-gray-color);
  padding-right: 20px;
}

.topCardLeftItem + .topCardLeftItem {
  margin-left: 20px;
}

.topCardLeftItem:last-child {
  border: none;
}

.topCardLeftItem span {
  color: var(--portal-dark-gray-color);
  font-size: 12px;
  margin-bottom: 5px;
}

.topCardRight {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topCardRight span {
  color: var(--portal-dark-gray-color);
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
}

.topCardRight h4 {
  display: flex;
  align-items: center;
  color: var(--dash-sidebar-secondary);
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.topCardRight h4 b {
  font-size: 30px;
}

.topCardRight h5 {
  margin: 0;
}

.topCardRightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--new-dashboard-gray-light);
  border-radius: 5px;
  margin-bottom: 17px;  
  background: var(--white-color);
}

.topCardRightContentTop {
  border-bottom: 1px solid #E2E2E2;  
  padding: 0 20px;
}

.topCardRightContentTop p {
  font-size: 20px;
  color: #11183F;
  font-weight: bold;
}

.topCardRightContentBottom {
  display: flex;
}

.topCardRightContentBottomLeft {
  display: flex;
  border-right: 1px solid #E2E2E2;
  padding: 20px;  
  gap: 40px;
}

.topCardRightContentBottomRight {
  display: flex;
}

.containerBody {    
  background: var(--white-color);
  padding: 30px 50px;
  border-radius: 8px;
  max-height: 80vh;
  overflow: auto;
  border: 1px solid var(--portal-gray-color);
}

.contentCard {
  display: flex;
}

.contentCard h3 {
  color: var(--dash-sidebar-secondary);
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
} 

.contentCardTop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;  
  border-bottom: 1px solid var(--portal-dark-gray-color);
}

.greenButton {
  background: var(--dash-green-color);
  color: var(--dash-green-dark-color);
  cursor: pointer;
  width: 165px;
  font-size: 12px;
  font-weight: normal;
  border-radius: 5px;
  padding: 10px 18px;
  border: none;
}

.statusContainer {
  display: flex;
  max-width: 500px;
  overflow: auto;
  padding: 0 0 10px 0;
  margin-right: 40px;
}

.container .containerBody .statusContainer span {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  min-width: 130px !important;
  min-height: 30px !important;
}

.container .containerBody .statusContainer span:not(:first-child) {
  background: #f5f5f5 !important;
  color: var(--portal-dark-gray-color) !important;
}

.container .containerBody .statusContainer span:not(:last-child) {
  margin-right: 15px;
}

.generalButton button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 90px;
  max-width: 150px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px 10px;
  border: none;
  height: auto;
  background: #5D3FD3;
  margin: 0 10px 0 10px;
}

.generalButton button svg {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.buttonsContainer {
  display: flex;
}

.buttonsContainer button:first-child {
  margin-right: 10px;
}

.modalTop {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}

.modalTop h3 {
  font-size: 15px;
  font-weight: 700;
  color: var(--dash-sidebar-secondary);
  padding: 15px;
}

.modalTop .close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: var(--portal-secondary-light-color);
}

.modalTop .close svg {
  fill: var(--portal-dark-gray-color);
  width: 25px;
  height: 25px;
}

.contenTop {
  display: flex;
  align-items: center;
  margin: 15px 5px 10px 5px;
}

.contenTop h5 {
  margin-right: 10px;
}

.modalInputs {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}


.modalInputs > div {
  margin: 5px;
}

.modalInputsRadios {
  display: flex;
}

.modalInputsRadios > div {
  margin: 5px;
}

.modalInputsContent {
  display: flex;
  flex-wrap: wrap;
}

.modalInputsContent > div {
  width: calc(50% - 15px);
  margin: 5px;
}

.containerButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.containerButtons button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 135px;
  width: 135px;
  margin: 5px; 
  font-size: 12px;
  height: 35px;
  flex-basis: 180px;
}

.containerButtons button:first-child {
  color: var(--portal-dark-gray-color);
  background-color: #fff;
  border: 1px solid  var(--portal-dark-gray-color)
}

.textarea {
  margin: 5px;
}

.reason {
  color: var(--portal-dark-gray-color);
  font-size: 12px;
  margin: 30px 0 0 0;
}

.contactFire {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.contactFire span {  
  margin-bottom: 0;
}

.contactFire img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
} 

.contactSpan {
  margin-left: 20px;
  margin-top: 5px;
}

.marginTopSpan {
  margin-top: 5px;
}

.containerTopRight {
 padding: 0;
}

.containerTopRight .rightStatus {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  max-width: 100%;
  font-weight: normal;
  margin-top: 12px;
}

.containerTopRight .rightStatus h4 {
  font-size: 30px;
  font-weight: normal;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--dash-sidebar-secondary);
}

.topCardRightContentBottomRight {
  display: flex;
  gap: 40px;
  padding: 20px;
}

.rightStatus h5 {
  color: #334588;
  font-size: 10px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.rightStatus span {
  font-size: 10px;
}

/* invoices */
.modalContainerFeedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
}

.modalContainerFeedback h4 {
 color: var(--dash-sidebar-secondary);
 font-size: 15px;
 font-weight: normal;
}

.modalContainerFeedback svg {
  width: 64px;
  height: 64px;
  background: var(--dash-sidebar-secondary);
  border-radius: 50%;
  padding: 20px
 }

 .currencyContainer {
  display: flex;
  flex-direction: column;
 }

 .currencyContainer span {
  color: #C5C5C5;
  font-size: 10px;
  margin-bottom: 10px;
 }

 .currencyContainer b {
  color: #C5C5C5;
  font-size: 20px;
  font-weight: bold;
 }

 
 .currencyContainerActive span {
  color: #1B2752;
  font-size: 10px;
  margin-bottom: 10px;
 }

 .currencyContainerActive b {
  color: #1B2752;
  font-size: 20px;
  font-weight: bold;
 }

 .containerBottom {
  display: flex;
  margin: 35px 35px 0 45px;
  gap: 40px;
 }

 .containerBottom > * {
  width: 50%;
 }

 @media (max-width: 1366px) {

  .whatsappContainer {
    max-width: 500px;
  }

  .containerBody .contentCard .contentCardTop {
    display: flex;
    flex-direction: column;
  }

  .buttonsContainer {
    margin: 20px 0;
  }
 }

  @media (max-width: 1166px) {    
    .containerBottom {
      flex-wrap: wrap;
    }

    .whatsappContainer {
      max-width: 400px;
    }
  }

  @media (max-width: 1066px) {
    .whatsappContainer {
      max-width: 300px;
    }
}
