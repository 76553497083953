@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 800px;
  overflow: auto;
  min-height: 250px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--portal-gray-color);
  padding: 0 40px;
}

.tabs button:not(.rightButton button) {
  font-size: 13px;
  color: var(--portal-dark-gray-color);
  padding: 20px 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  margin-right: 50px;
  outline: none;
}

.tabs button.active:not(.rightButton button) {
  color: var(--dash-sidebar-secondary);
  font-weight: 600;
  border-bottom: 3px solid #5D3FD3;
}

.tabs .tab-content > div {
  padding: 10px;
}

.tabsTop {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--portal-gray-color);
  padding: 0 30px;
}

.tabsTopTeam {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabsTopReason {
  display: flex;
  padding: 0 10px;
}

.tabsTopReason .containerReason {
  display: flex;
  align-items: center;
  width: 100%;
  background: #F2F5FB;
  color: #546890;
  font-size: 11px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0 15px 0;
}

.tabsContent {
  padding: 20px 40px;
}

.tabsContent.noPadding {
  padding: 0;
}

.filter {
  margin: 18px 0;
}

.filterteam {
  margin: 0 0 18px 0;
}

/* customer */

.rightButton {
  display: block;
  margin-left: auto;
  margin-right: 0;
  opacity: 1;
  padding: 0 10px;
}

.rightButton button {
  width: 130px;
  min-width: 130px;
  height: 30px;
  font-size: 10px;
  text-align: center;
  padding: 8px;
  margin-top: 10px;
  background: #5D3FD3;
}