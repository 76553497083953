@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapper label {
  text-transform: none;
  color: var(--new-dashboard-primary);
}

.wrapper input {
  border-radius: 5px;
  border: 1px solid var(--portal-dark-gray-color);
  padding: 15px 20px;
  font-size: 15px;
  color: var(--portal-dark-gray-color);
}

.fullWidth {
  width: 100%;
  max-width: 100%;
}
.small {
  width: 120px;
}