@import url('../../assets/styles/variables.css');

.title {
  font-family: var(--primary-font);
  font-size: 1.8em;
}

.default {
  color: var(--catskill-white-color);
}

.info {
  color: var(--bunting-color);
}

.xsm {
  font-size: 0.8em;
}

.sm {
  font-size: 1em;
  line-height: 1em;
}

.md {
  font-size: 1.250em;
  margin: 0;
  line-height: 30px;
}

.lg {
  font-size: 1.5em;
}

.xl {
  font-size: 1.8em;
}

.xxl {
  font-size: 1.875em;
  margin: 0;
  font-weight: 700;
}

.primary {
  color: var(--portal-secondary-color);
}

.white {
  color: var(--white-color);
}

.gray {
  color: var(--portal-gray-color);
}

@media only screen and (min-width: 1100px) {
  .xxl {
   font-size: 2.2em;
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}