@import url('../../../assets/styles/variables.css');

.wrapper {
  width: 100%;
  font-family: var(--new-dashboard-primary-font);
}

.wrapper .legend {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wrapper .legend li {
  font-family: var(--new-dashboard-primary-font);
  font-size: 14px;
  color: var(--black-color);
  font-weight: normal;
  margin-bottom: 15px !important;
}

.wrapper .legend li .piePercentage {
  font-family: var(--new-dashboard-primary-font);
  font-weight: 700;
  margin-right: 5px;
}

.wrapper .legend li .span {
  font-size: 13px;
  font-family: var(--new-dashboard-primary-font);
}

.wrapper .topContent {
  font-family: var(--new-dashboard-primary-font);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}


.wrapper h1 {
  font-family: var(--new-dashboard-primary-font);
  font-size: 20px;
  color: var(--new-dashboard-secondary);
  font-weight: 700;
}

.wrapper .tooltip {
  font-family: var(--new-dashboard-primary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 46px;
  background-color: var(--new-dashboard-secondary);
  border-radius: 12px;
}

.wrapper .tooltip p {
  font-family: var(--new-dashboard-primary-font);
  color: var(--white-color);
  font-size: 11px;
  font-weight: 600;
  padding: 10px;
}

@media (min-width: 600px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}