.radioGroup {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
}

.radioGroup label {  
  margin-top: 10px;
}

@media only screen and (min-width: 800px) {
  .radioGroup {  
    flex-direction: row;
    justify-content: center;
  }

  .radioGroup label {
    max-width: 500px;
    margin: 0 20px;
  }
}