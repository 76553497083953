@import url('../../../assets/styles/variables.css');

.wrapper {
  font-family: var(--primary-font);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.wrapper .topContent {
  font-family: var(--primary-font);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}

.wrapper h1 {
  font-family: var(--primary-font);
  font-size: 20px;
  color: var(--new-dashboard-secondary);
  font-weight: 700;
  padding: 10px;
}

.wrapper .tooltip {
  font-family: var(--primary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 46px;
  background-color: var(--new-dashboard-secondary);
  border-radius: 12px;
}

.wrapper .tooltip p {
  font-family: var(--primary-font);
  color: var(--white-color);
  font-size: 11px;
  font-weight: 600;
  padding: 10px;
}

.wrapper .legend {
  font-family: var(--primary-font);
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 10px;
  font-weight: normal;
  color: var(--black-color);
}

.wrapper .tickText {
  font-family: var(--primary-font);
  font-size: 12px;
  color: var(--portal-dark-gray-color);
}

.customLegend {
  font-family: var(--primary-font);
  margin-top: 30px;
}