@import url('../../assets/styles/variables.css');

.layout {
  min-height: 100vh;
  overflow: hidden;
}

.layoutHeader {
  margin: 0 auto;
  max-height: 50px;
  max-width: 200px;
  overflow: hidden;
  padding: 60px 0 20px 0;
}

.layoutLogoMobile {
  width: 100%;
}

.layoutBgCircle1,
.layoutBgCircle2,
.layoutBgCircle3,
.layoutBgCircle4 {
  background-image: url('../../assets/images/bg-circle.png');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 150px;
  content: '';
  display: block;
  height: 150px;
  position: fixed;
  width: 150px;
}

.layoutBgCircle1 {
  top: 14px;
  left: -117px;
}

.layoutBgCircle2 {
  top: 126px;
  left: 389px;
}

.layoutBgCircle3 {
  top: 642px;
  left: -60px;
}

.layoutBgCircle4 {
  top: 701px;
  left: 307px;
}

.layoutDash {
  font-family: var(--new-dashboard-primary-font);
  width: auto;
  height: 100vh;
  display: grid;
  grid: "sidebar body" 1fr / auto 1fr;
  gap: 0px;
}

.sidebar { 
  background-color: #1B2752;
  grid-area: sidebar;
  width: auto;
}

.body { 
  grid-area: body;
  background-color: #F9F9F9;
}

.menuDash {
  font-family: var(--new-dashboard-primary-font);
  margin: 55px 0 0 0;
  padding: 0;
  height: calc(100% - 300px);
}

.menuDash > * {
  font-family: var(--new-dashboard-primary-font);
  margin: 0;
}

.menuItem.menuItemLogout {
  color: var(--new-dashboard-gray);
  margin-top: auto;
}

.menuItemLogout i svg {
  fill: var(--new-dashboard-gray);
}

.menuItem, .submenus, .submenus .menuItem {
  font-family: var(--new-dashboard-primary-font);
  display: flex;
  align-items: center;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: var(--new-dashboard-gray);
  padding: 9px 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  width: 100%;
  margin-bottom: 15px;
  min-height: 50px;
}

.submenus .menuItem:nth-child(2)  {
  margin-top: 10px;
}

.icon {
  line-height: 0;
}

.icon svg {
  width: 20px;
  height: 20px;
  fill: var(--new-dashboard-gray);
  margin-right: 20px;
}

.menuItemActivated {
  background: var(--white-color);
  color: var(--new-dashboard-secondary);
  border-left: 3px solid var(--new-dashboard-secondary);
  font-weight: 500;
  transition: all 0.5s ease-in-out;
  transition: background-color 1s ease-in-out;
}

.staticMenu.menuItemActivated {
  animation: ease .5s ease-in;
}

.staticMenu {
  margin: 0 !important;  
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.staticMenu i:last-child {
  margin-left: 15px;
}
.staticMenu.menuItemActivated i:last-child {
  opacity: 0.8;
}


.submenus li:nth-child(n+2) {
  font-size: 14px;
}

.submenus li:nth-child(n+2) span {
  margin-left: 40px;
}

@keyframes changeBackgroundColor {
  0% {
    background-color: #fefefe;
  }
  50% {
    background-color: #fefefe;
  }
  100% {
    background-color: var(--white-color);
  }
}

.menuItemActivated .icon svg {
  fill: var(--new-dashboard-secondary);
}

.dashLogo {
  width: 100%;
  overflow: hidden;
  padding: 50px 0;
}

.dashLogo img {
  display: block;
  max-width: 210px;
  margin: 0 auto;
}

.logoDashboard {
  height: 45px;
}

.toolsBar {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: auto;
  right: 20px;
  top: 20px;
}

.loggoutButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.submenus {
  display: flex;
  flex-direction: column;
  background: transparent;
  
  margin: 0 !important;
  padding-top: 0;
}

.submenus li:not(:first-child) svg {
  margin-left: 20px;
}

@media only screen and (min-width: 1100px) {
  .layoutHeader {
    max-height: 100px;
    max-width: 400px;
  }
}

/* Operator */

.dashLogoOperator {
  padding: 45px 0;
}

.dashLogoOperator img {
  max-width: 130px;
  height: 30px;
}

.menuDashOperator {
  margin-top: 0;
}

.menuDashOperator img {
  margin-top: 0;
}

.menuDashOperator li {
  color: var(--portal-gray-color);
  font-size: 14px;
  max-width: 81%;
}

.menuDashOperator li i {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.menuDashOperator li svg {
  fill: var(--portal-gray-color);
  width: 25px;
  height: 25px;
}

.menuDashOperator .menuItemActivated {
  color: var(--new-dashboard-primary);  
  border-right: 6px solid var(--new-dashboard-primary);
  border-left: none;
}

.menuDashOperator .menuItemActivated svg {
  fill: var(--new-dashboard-primary);
}