@import url('../../../assets/styles/variables.css');

.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  background: var(--portal-white-color);
}

.homeSize {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  min-height: 70vh;
  padding: 0 70px;
  max-width: 500px;  
  overflow: hidden;
}

.containerLimit {
  max-width: 245px;
}

.infoText {
  color: var(--secondary-color);
}

.layoutHeader {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-height: 50px;
  max-width: 200px;
  overflow: hidden;
  padding: 55px 0 0 0;  
}

.layoutLogoMobile {
  max-width: 130px;
}

.opaqueBackground {
  opacity: 0.5;
}

.bottomModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 240px;
  margin: 0 auto;
}

.bottomModalContent h3 {
  color: #334588;
  font-size: 20px;
  text-align: left;
}

.bottomModalContent p {
  text-align: center;
  color: #B9B9B9;
  font-size: 15px;
} 

.bottomModalContent button {
  margin-top: 30px;
  max-width: 240px;
  min-width: 240px;
}

.bottomModalContent input::placeholder {
  text-transform: uppercase;
}

.footer {
  width: 100%;
  min-height: 30vh;
  background-color: var(--portal-primary-color);
  background-image: url('../../../assets/images/homeimage.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1100px) {
  .layoutHeader {
    max-height: 100px;
    max-width: 400px;
  }
  
  input {
    max-width: 470px;
  }
  
  .homeContainer input {
    min-width: 470px;
  }
  .containerLimit {
    max-width: 100%;
  }

  .bottomModalContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .bottomModalContent button {
    margin-top: 30px;
    max-width: 100%;
    min-width: 240px;
  }
}

/* Whitelabel */
.whitelabelFooterEllipses {
  padding-left: 40px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.whitelabelFooterEllipses svg {
  transform: translateY(1px);
}

.whitelabelFooterEllipses svg:nth-child(2) {
  transform: translateX(-20px);
}

.whitelabelHeaderEllipses {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.whitelabelHeaderLeftEllipses {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.whitelabelTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whitelabelTop h1, .whitelabelTop b {
 font-size: 19px;
 text-align: center;
 font-weight: normal;
 color: var(--portal-secondary-color);
}

.whitelabelTop h1 {
  margin-bottom: 0;
}

.whitelabelTop b {
  font-weight: 700;
}

.whitelabelMiddle {
  margin-top: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.whitelabelMiddle p span {
  display: flex;
  align-items: flex-start;
  font-size: 10px;
  color: var(--portal-secondary-color);
}

.whitelabelMiddle p span svg {
  margin-left: 2px;
  margin-top: 2px;
}

