.navbar {
  width: 100px;
  min-height: 100vh;
  background-color: #1B2752;
  transition: width 200ms ease; /* Transição suave para a largura */
}

.navbar:hover {
  width: 267px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: padding 200ms ease; /* Transição suave para o padding */
}

.navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-item {
  position: relative;
  overflow: hidden;
  transition: all 200ms ease; /* Transição suave ao expandir/contrair */
}

.nav-item.active::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #fff;
  transform: translate(50%, -50%) rotate(45deg);
}

.nav-link {
  display: flex;
  align-items: center;
  height: 80px;
  text-decoration: none;
  color: #fff;
  transition: padding 200ms ease;
}

.nav-link img {
  margin: 0 24px 0 32px;
  width: 30px;
  height: 30px;
}

.link-text {
  display: inline-block;
  font-family: Esphimere;
  font-size: 15px;
  text-wrap: nowrap;
  opacity: 0;
  transition: opacity 200ms ease;
  white-space: nowrap;
  overflow: hidden;
}

.nav-item.active .link-text {
  font-weight: bold;
}

.navbar:hover .link-text {
  opacity: 1;
}

.logo-margin {
  padding: 0;
  transition: padding 200ms ease;
}

.logo-margin img {
  max-width: 200px;
  transition: max-width 200ms ease;
}
