@import url('../../assets/styles/variables.css');

.input {
  color: var(--portal-gray-color);
  background-color: var(--white-color);
  border: 1px solid var(--portal-gray-color);
  border-radius: 5px;
  padding: 10px 20px;
  text-align: left;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.750em;
  height: 40px;
  width: 245px;
}

.input:active, .input:focus {
  border: 1px solid var(--portal-secondary-color);
  outline: none;
}
