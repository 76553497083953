@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  background: var(--white-color);
  padding: 30px 50px;
  border-radius: 8px;
  margin: 0 0 15px 0;
  border: 1px solid var(--portal-gray-color);
}

.noPadding {
  padding: 0;
}

.padding25 {
  padding: 25px;
  min-height: 338px;
}