@import url('../../assets/styles/variables.css');

.content {
  width: 100%;
  max-width: 350px;
}

.content label {
  text-transform: none;
  color: var(--new-dashboard-primary);
  display: block;
  margin-bottom: 5px;
}

.fullWidth.wrapper {
  width: 100%;
  height: 50px;
}

.fullWidth.wrapper::after  {
  top: 20px;
}

.fullWidth.wrapper select  {
  height: 50px;
}

.wrapper {
  width: 190px;
  height: 35px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--new-dashboard-gray);
  margin-right: 10px;
}

.wrapper::after {
  content: "\25BC";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 0;
  padding: 0;
  background: #fff;
  pointer-events: none;
  transition: 0.25s all ease;
  color: var(--new-dashboard-gray);
  font-size: 12px;
  transition: transform 0.25s ease;
}

.wrapper.active::after {
  content: "\25B2";
}

.wrapper:hover::after {
  color: var(--new-dashboard-gray);
}

.wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: 0;
  border: none;
  background-color: transparent;
  color: var(--new-dashboard-gray);
  overflow: auto;
  border-radius: 5px;
  padding: 5px 18px;
  height: 35px;
}

.wrapper select::-ms-expand {
  display: none;
}

.wrapper select option {
  display: block;
  padding: 20px 18px;
  cursor: pointer;
}

.wrapper.inputStyle {
  transform: translateY(5px);
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--new-dashboard-gray);
  font-size: 15px;
  color: var(--new-dashboard-gray);
  padding: 5px 0;
}

.wrapper.inputStyle select {
  background-color: #fff;  
}

.wrapper.inputStyle::after {
  background-color: #fff;
  top: 15px;
}


