.spinner {
  width: 64px;
  height: 64px;
  background-image: url('./images/loading.png');
  background-size: cover;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
