@import url('../../assets/styles/variables.css');

.wrapper {
  margin: 5px 15px 5px 5px;
}

.wrapper textarea {
  width: calc(100% - 40px);
  padding: 20px;
  color: var(--portal-dark-gray-color);
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid var(--portal-dark-gray-color);
  resize: none;
}