@import url('../../../assets/styles/variables.css');

.paymentContainer {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.paymentCode {
  background-color:var(--athens-gray-color);
  border-radius: 15px;
  color: var(--black-color);
  font-size: 1.3em;
  height: 150px;
  overflow: hidden;
  padding: 10px;
  word-wrap: break-word;
}

.top {
  display: flex;
  background-color: var(--portal-secondary-color);
  width: 100%;
  padding: 0 40px;
  min-height: 260px;
}

.topContent {
  width: calc(55% - 15px);
  margin: 130px 0 0 15px;
}

.topContent h1:nth-child(2) {
  font-weight: normal;
  line-height: 22px;
}

.top .goBackButton {
  width: 20px;
  margin-top: 50px;
}

.customImage {
  width: 45%;
  background-image: url('../../../assets/images/pastDue.svg');
  background-repeat: no-repeat;
  background-position: center;
  min-width: 100px;
  min-height: 260px;
}

.content {
  padding: 70px 35px;
}

.text {
  margin-bottom: 50px;
}

.paymentContainer label:last-of-type > div {
  border: none;
}

@media only screen and (min-width: 1100px) {
  .paymentContainer {
    max-width: 100%;
  }
}
