@import url('../../assets/styles/variables.css');

.btnUpload {
  border: none;
  color: var(--bunting-color);
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
  text-decoration: underline;
}

.uploadFileContainer {
  display: flex;
  width: 100%;
}

.uploadFileContainer > div {
  flex: 1;
  margin: 0 20px;
}

.btnUpload:hover {
  opacity: 0.9;
}

.inputFileTrigger {
  text-align: center;
  display: block;
  padding: 14px 45px;
  background: #F9F9F9;
  box-sizing: border-box;
  border: 1px solid var(--athens-gray-color);
  border-radius: 8px;
  color: var(--bunting-color);
  font-size: 12px;
  transition: all .4s;
  cursor: pointer;
  width: 100%;
  min-height: 220px;

}

.listContainer {
  display: flex;
  flex-direction: column;
  background: #F9F9F9;
  box-sizing: border-box;
  border: 1px solid var(--athens-gray-color);
  border-radius: 8px;
  color: var(--bunting-color);
  font-size: 12px;
  transition: all .4s;
  cursor: pointer;
  width: 100%;
  min-height: 220px;
}

.inputFile {
  position: absolute;
  top: -62px;
  left: 0;
  width: 50%;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}

.inputIcon {
  position: relative;
  top: 10px;
}

.inputListFiles {
  width: 100%;
}

.triggerIcon {
  display: block;
  margin-bottom: 40px;
}

.contentInputFile {
  width: 100%;
}

.inputActions {
  text-align: center;
}

.fileItens {
  padding: 0 15px;
}


.itemNameFile,
.itemSizeFile {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  color: var(--bunting-color);
  letter-spacing: 0.2px;
  margin: 0;
  padding: 0;
}

.itemNameFile {
  font-size: 12px;
  line-height: 20px;
}

.itemSizeFile {
  font-size: 12px;
}

.fileItem {
  display: flex;
  padding: 5px;
  width: 100%;
  background: var(--athens-gray-color);
  border-radius: 8px;
  margin: 5px 0;
}

.avatarFile {
  text-align: center;
  letter-spacing: 0.2px;
  color: var(--bunting-color);
  border: 1px solid #11183F;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  border-radius: 50%;
  padding: 10px 6px;
  margin-right: 10px;
}

.modalContainer {
  width: 500px;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 18px;
}


.modalContainer p {
  font-size: 18px;
  margin-bottom: 50px;
  color: var(--bunting-color);
}

.modalContainer span {
  font-weight: bold;
}

.modalContainer img {
  width: 80px;
  height: 75px;
  margin-bottom: 30px
}

.btnSubmit {
  border: none;
  background: #11183F;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
}

.btnSubmit:hover {
  opacity: 0.9;
}

.listContainer {
  cursor: default;
}

.inputFileUploadContainer h1, .list h1 {
  font-size: 16px;
  font-weight: bold;
  font-family: var(--primary-font);
  color: var(--bunting-color);
  text-align: center;
}