@import url('../../assets/styles/variables.css');


.datePicker {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  height: 45px !important;
  box-sizing: border-box !important;

  border-radius: 5px !important;
  border: 1px solid var( --new-dashboard-gray) !important;
  padding: 15px 20px !important;
  font-size: 15px !important;
  color: var( --new-dashboard-gray) !important;

  text-align: center !important;
}

.popper {
  width: 259px !important;
  height: 259px !important;
  text-align: center !important;
}

.popper .react-datepicker__day-names {
  display: none !important;  
}

:global(.custom-ranger .react-datepicker) {
  width: 100% !important;
}

:global(.custom-ranger .react-datepicker .react-datepicker__month-container) {
  width: 100% !important;
}

:global(.custom-ranger .react-datepicker .react-datepicker__day-names) {
  color: var(--portal-gray-color);
  font-size: 12px;
  width:  100%;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 5px 0;
}

:global(.custom-ranger .react-datepicker .react-datepicker__day-name) {
  font-size: 0; 
}

:global(.custom-ranger .react-datepicker .react-datepicker__day-name::first-letter ) {
  font-size: 12px;
  display: inline-block;
  text-transform: uppercase;
}


:global(.custom-ranger .react-datepicker .react-datepicker__header::after) {
  content: "" !important;
}

:global(.custom-ranger .calendar-button) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #CDCDCD;  
  background: #FFF;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}

:global(.custom-ranger .calendar-button .calendar-button-texts) {
  display: flex;
  align-items: center;
}

:global(.custom-ranger .calendar-button .calendar-button-texts img) {
  margin-right: 10px;
}

:global(.custom-ranger .react-datepicker__header span) {
  color: #666;
  font-size: 13px;
  font-weight: normal;
}

.dateRangeDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
