@import url('../../assets/styles/variables.css');

.selectWrapper {
  width: 100%;
  height: 50px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 10px;
}

.selectWrapper::after {
  content: "\25BC";
  position: absolute;
  top: 20px;
  right: 10px;
  bottom: 0;
  padding: 0;
  background: #fff;
  pointer-events: none;
  transition: 0.25s all ease;
  color: var(--new-dashboard-gray);
  height: 4px;
  font-size: 12px;
  font-weight: bold;
  transition: transform 0.25s ease;
}

.selectWrapper.active::after {
  content: "\25B2";
}

.selectWrapper:hover::after {
  color: var(--new-dashboard-gray);
}

.selectWrapper select.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: 0;
  border: none;
  background-color: #fff;
  color: var(--new-dashboard-gray);
  overflow: auto;
  border-radius: 5px;
  padding: 5px 18px;
  height: 50px;
  border: 1px solid var(--new-dashboard-gray);
}

.selectWrapper select.input::-ms-expand {
  display: none;
}

.selectWrapper select.input option {
  display: block;
  padding: 20px 18px;
}

.selectorContainer label {
  text-transform: none;
  color: var(--new-dashboard-primary);
  display: block;
  margin-bottom: 5px;
}