.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 414px;
  height: 184px;
  margin-top: 0;
  padding-top: 0;
}

.modal .modalContent p  {
  color: #120638;
  font-family: "Esphimere";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.modal .modalContent p b  {
  display: block;
}

.top {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
}

.close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: var(--portal-secondary-light-color);
}

.close svg {
  fill: var(--portal-dark-gray-color);
  width: 25px;
  height: 25px;
}