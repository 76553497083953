@import url('../../../assets/styles/variables.css');

.paymentContainer {
  text-align: center;
}

.top {
  display: flex;
  background-color: var(--portal-primary-color);
  width: 100%;
  padding: 50px 40px 0 0;
  min-height: 260px;
}

.top .goBackButton {
  width: 20px;
  margin-left: 20px;
}

.topContent {
  width: 300px;
  padding: 40px 0 0 0;
  text-align: center;
}

.topContent h1:nth-child(2) {
  font-weight: normal;
  line-height: 22px;
}

.content {
  margin: 50px 30px;
}

.content p {
  font-family: var(--primary-font);
  font-size: 15px;
  line-height: 25px;
  color: var(--portal-secondary-color);
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}

.content p span {
  font-weight: 700;
}

.paymentBottom {
  margin: 55px auto 65px auto;
  width: 276px;
  display: flex;
  justify-content: space-around;
  padding: 22px;
  font-family: var(--primary-font);
  color: var(--portal-secondary-color);
  border-radius: 20px;
  background: var(--portal-secondary-light-color);
}

.paymentContent h2 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.paymentContent h3 {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
}

.paymentContent span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0;
}

.modalContainer {
  height: 200px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}


.modalContainer p {
  margin-bottom: 40px;

  color: #11183F;
}

.modalContainer span {
  font-weight: bold;
}

.modalContainer img {
  height: 70px;
  /* width: 30px; */
}

.btnSubmit {
  border: none;
  background: #11183F;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 0.7em;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out .5s;
  cursor: pointer;
}

.btnSubmit:hover {
  opacity: 0.9;
}

.bottomModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  margin: 0 auto;
}

.bottomModalContent h3 {
  color: var(--portal-secondary-color);
  font-size: 20px;
  margin-bottom: 50px;
}

.bottomModalContent input {
  margin-bottom: 25px;
  margin-bottom: 20px;
}

.bottomModalContent p {
  color: var(--portal-gray-color);
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  font-weight: 600;
}

.opacityLow {
  opacity: 0.3;
}

@media screen and (min-width: 1000px) {
  .bottomModalContent {
    max-width: 500px;
  }
}
