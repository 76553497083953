
@import url('../../assets/styles/variables.css');

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100% - 40px);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;  
  border-radius: 40px;
}

.top {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 40px);
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  color: var(--portal-gray-color);
}

.closeButton:hover {
  cursor: pointer;
}