@import url('../../assets/styles/variables.css');

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.header span::first-letter {
  text-transform: uppercase;
}

.header span {
  color: var(--portal-gray-color);
  font-size: 12px;
  font-weight: 700;
}

.header button {
  background: none;
  border: none;
  cursor: pointer;
}

.header button svg {
  width: 7px;
  fill: var(--portal-gray-color);
}

