@import url('../../assets/styles/variables.css');
:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

label {
  width: 100%;
}

.cardInputElement {
  display: none;
}

.cardInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  background: #EEF2F6;
  border: 5px solid var(--portal-primary-color);
  padding: 34px;
  border-radius: 14px;
  box-sizing: border-box;
  max-width: 500px;
  margin: 0 auto;
}

.cardInput:hover {
  cursor: pointer;
}

.cardInputElement:checked + .cardInput {
  border-color: var(--portal-primary-color);
}

.radioCircle {
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  border: 3px solid var(--portal-primary-color);
  padding: 2px;
}

.radioDot {
  content: "";
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
}

.cardInputElement:checked + .cardInput {
  border-color: var(--portal-primary-color);
}

.cardInputElement:checked + .cardInput > .radioCircle > .radioDot {
  background: var(--portal-primary-color);
}

.icon {
  display: flex;
  flex-direction: column;
}

.icon img {
  max-width: 100%;
  object-fit: contain;
}

.description {
  color: #11183F;
  text-transform: none;
  text-align: center;
}

.column {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}