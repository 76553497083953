@import url('../../assets/styles/variables.css');

.wrapper {
  height: 100%;
  margin-bottom: 15px;
}

.wrapper h4 {
  color: var(--dash-sidebar-secondary);
  font-size: 15px;
  font-weight: 700;
}

.wrapper h3 {
  color: var(--dash-sidebar-secondary);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.wrapper h5 {
  color: var(--portal-dark-gray-color);
  font-size: 12px;
  font-weight: 500;
  margin-top: 35px;
  margin-bottom: 10px;
}

.progressBarContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.progressBarContainer .barContainer:first-of-type h5 {
  margin-top: 0;
}

.barContainer {
  width: 100%;
}

.barContainer > div {
  width: 100%;
}

.customProgressBar {
  border-radius: 0;
  height: 9px;
}

.barContainer .customProgressBar div {
  border-radius: 0 !important;
  height: 9px !important;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.titleContainer span {
  color: var(--portal-dark-gray-color);
  font-size: 10px;
  margin-bottom: 10px;
}

.tooltipBlue, .tooltipViolet, .tooltipLightBlue, .tooltipGreen {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  line-height: 17px;

  display: block;
  width: 72px;
  height: 19px;
  text-align: center;
  font-size: 10px;
  border-radius: 2px;
  color: var(--white-color);
  transform: translateY(-40px);
}

.tooltipBlue {
  background: var(--portal-primary-color);
  margin-left: calc(var(--pastDues) - 15px);
}

.tooltipViolet {
  background: #635AD9;
  margin-left: calc(var(--userPastDuesPromises) - 15px);
}

.tooltipLightBlue {
  background: #88F4FF;
  margin-left: calc(var(--userPastDuesGenerated) - 15px);
}

.tooltipGreen {
  background: #12D849;
  margin-left: calc(var(--userPastDuesPaid) - 15px);
}


.tooltipBlue::after, .tooltipViolet::after, .tooltipLightBlue::after, .tooltipGreen::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -25px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--portal-primary-color) transparent transparent transparent;
}

.tooltipViolet::after {
  border-color: #635AD9 transparent transparent transparent;
}

.tooltipLightBlue::after {
  border-color: #88F4FF transparent transparent transparent;
}

.tooltipGreen::after {
  border-color: #12D849 transparent transparent transparent;
}


.progressBarHover:hover .tooltipBlue, .progressBarHover:hover .tooltipViolet, .progressBarHover:hover .tooltipLightBlue, .progressBarHover:hover .tooltipGreen {
  opacity: 1;
  visibility: visible;
}