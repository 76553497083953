@import url('../../../assets/styles/variables.css');

.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;
  background-color: var(--primary-color);
  min-height: 100vh;
  overflow: hidden;
  background: linear-gradient(180deg, var(--primary-color) 64%, rgba(255,255,255,1) 100%);
}

.infoText {
  color: var(--secondary-color);
}

.layoutHeader {
  margin: 0 auto;
  max-height: 50px;
  max-width: 200px;
  overflow: hidden;
  padding: 60px 0 20px 0;
}

.layoutLogoMobile {
  width: 100%;
}

.homeSize {
  max-width: 500px;
  margin: 0 auto;
}

@media only screen and (min-width: 1100px) {
  .layoutHeader {
    max-height: 100px;
    max-width: 400px;
  }

  input {
    max-width: 470px;
  }
}