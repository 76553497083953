@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item, .itemCondition {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background-color: var(--white-color);
  cursor: pointer;
  color: var(--portal-dark-gray-color);
  font-size: 12px;
  font-weight: 500;
}

.itemCondition {
  padding: 7px 20px;
  border-bottom: 1px solid #EBEBEB;
  justify-content: space-between;
}

.itemContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.itemContainer h4 {
  color: var(--new-dashboard-primary);
  font-size: 11px;
  font-weight: 700;
}

.itemContainer h5 {
  color: var(--new-dashboard-primary);
  font-size: 11px;
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
}

.itemContainer span {
  color: var(--new-dashboard-gray);
  font-size: 11px;
  font-weight: 500;
}


.item.itemArrow {
  justify-content: flex-start;
}

.item.main {
  align-items: center;
  background-color: #F2F5FB;
  cursor: default;  
  justify-content: space-between;
}

.item.main .contentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item.main span {
  color: var(--portal-dark-gray-color);
  font-size: 11px;
  margin-bottom: 10px;
}

.item.main b {
  color: var(--bunting-color);
  font-size: 11px;
  font-weight: 700;
}

i {
  margin-right: 10px;
}

i svg {
  width: 12px;
  height: 12px;
}