.wrapper {
  display: flex;
  flex-direction: column;
  padding: 17px 45px;
}

.wrapper thead tr, .wrapper tbody tr {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 12px;
}

.wrapper thead tr th, .wrapper tbody tr td {
  flex: 1;
  text-align: left; 
}

.wrapper thead tr th {
  color: #B1B1B1;
  font-size: 13px;
  font-weight: 400 !important;
}

.wrapper tbody tr td {
  padding: 20px 0 0 0;
}
