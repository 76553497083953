@import url('../../assets/styles/variables.css');

.btn {
  background-color: var(--portal-primary-color);
  border: none;
  border-radius: 5px;
  color: var(--white-color);
  font-size: 1em;
  font-family: var(--secondary-font);
  height: 45px;
  width: 245px;
  padding: 0 50px;
  outline: none;
  cursor: pointer;
}

.btn:hover {
  opacity: 0.8;
}

.white {
  background: var(--white-color);
  color: var(--portal-primary-color);
}

.dark {
  background: var(--portal-secondary-color);
  color: var(--white-color);
}

@media only screen and (min-width: 1100px) {
  .btn {   
   min-width: 470px;
  }
}