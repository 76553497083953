@import url('../../assets/styles/variables.css');

.modal {
  width: 100%;
  min-height: calc(100vh + 160px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.container {
  margin-top: -120px;
  background-color: #fff;
  /* width: 60%;
  height: 60%; */
  color: black;


  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

}

.close {
  background-color: transparent;
  outline: none;
  width: 10px;
  height: 10px;
  border: none;
  right: calc(-100% + 30px);
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 20px;
  padding: 11px;
}

.close::after,
.close::before {
  content: ' ';
  position: absolute;
  width: 1.5px;
  height: 10px;
  background-color: #000;
}

.close::after {
  transform: rotate(-45deg);
}

.close::before {
  transform: rotate(45deg);
}