@import url("../../../../assets/styles/variables.css");

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 60px;

  /* overflow: hidden; */

  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #757a96;
  font-size: 14px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: normal;
}

.availableCredit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;

  background-color: #eef2f6;
  padding: 0px 5px;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.availableCredit span {
  font-weight: bold;
  color: #757a96;
}

.availableCredit p,
span {
  margin: 0;
  padding: 0;
}

.generalInfos {
  display: flex;
  flex-direction: column;

  text-align: justify;
}

.generalInfos p:first-child span {
  font-weight: bold;
}

.generalInfos p:not(:first-child) span {
  /* font-weight: bold; */
  color: #11183f;
  cursor: pointer;
}

.steps {
  display: grid;
  grid-template-columns: 35% 60%;
  grid-gap: 10% 5%;
}

.areaUpload {
  background-color: #eef2f6;
  border-radius: 6px;
  display: flex;
  padding-bottom: 30px;
}

.leftSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #11183f;
}

.leftSection img {
  height: 104px;
}

.select {
  width: 190px;
}

.analyticsFilter {
  background-color: #eef2f6;
  padding: 5px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.headerFilters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.headerFilters h2 {
  font-weight: 700;
  color: #11183f;
}

.headerFilters select {
  width: 100%;
}

.selectedFilters {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 100px; */
}

.selectedFilters p {
  margin: 0;
  padding: 0;
  color: #11183f;
  font-size: 17px;
  margin-bottom: 15px;
  font-weight: 700;
}

.cardSelectedFilters {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 15px;
  height: 160px;
  overflow: auto;
  border-radius: 8px;
  padding: 5px 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardSelectedFilters span:last-child {
  cursor: pointer;
}

.cardSelectedFilters div {
  display: flex;
  background-color: #dfe0eb;
  padding: 3px 10px;
  border-radius: 6px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  color: #11183f;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cardSelectedFilters::-webkit-scrollbar,
.historySection::-webkit-scrollbar {
  width: 12px;
  border-radius: 8px;
}

.cardSelectedFilters::-webkit-scrollbar-track,
.historySection::-webkit-scrollbar-track {
  background: #dfe0eb;
  border-radius: 8px;
  padding-left: 50px;
}

.cardSelectedFilters::-webkit-scrollbar-thumb,
.historySection::-webkit-scrollbar-thumb {
  background-color: #9fa2b4;
  border-radius: 20px;
  border: 3px solid transparent;
}

.btnSubmit {
  border: none;
  background: #11183f;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 0.7em;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 10px 24px;
  animation: all ease-in-out 0.5s;
  cursor: pointer;
}

.btnSubmit:hover {
  opacity: 0.9;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  background: green;
  background-image: none;
  border: none;
}

.select {
  position: relative;
  display: block;
  width: 100%;
  height: 2em;
  line-height: 2;
  background: #fff;
  color: black;
  overflow: hidden;
  border-radius: 0.25em;
}

select {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0 0 0.5em;
  color: #fff;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 1em;
  background: #ffffff;
  pointer-events: none;
}

.select:hover::after {
  color: #34495e;
}

.select::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

.historyContainer {
  background: #eef2f6;
  border-radius: 8px;
  padding: 20px;
  margin-top: 50px;
}

.historyContainer h2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;

  line-height: 21px;
  color: #11183f;
  margin: 0 30px 30px 30px;
}

.historySection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 30px;
  overflow: auto;
  max-height: 220px;
}

.historyContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.historyContent:hover {
  text-decoration: underline;
  cursor: pointer;
}

.historyDetails {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-right: 5px;
}

/* .historyDetails span:last-child {
  font-size: 12px;
  quando voltar  o peso do arquivo, descomentar essa linha de codido
} */

.modalContainer {
  height: 200px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}

.modalContainer p {
  margin-bottom: 40px;

  color: #11183f;
}

.modalContainer span {
  font-weight: bold;
}

.modalContainer img {
  height: 70px;
  /* width: 30px; */
}

.containerWithTitle {
  background: #eef2f6;
  border-radius: 8px;
  padding: 20px;
}

.containerWithTitle h2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;

  line-height: 21px;
  color: #11183f;
  margin: 0 30px 30px 30px;
}

.inputGroup {
  display: flex;
  gap: 15px;
  align-items: center;
}


.inputGroup input {
  margin: 0;
}

.inputGroup button {
  border: none;
  background: #11183f;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  color: #fff;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 5px 12px;
  animation: all ease-in-out 0.5s;
  cursor: pointer;
  height: 38px;
}