@import url('../../assets/styles/variables.css');

.input {
  background-color: var(--catskill-white-color);
  border: none;
  border-radius: 14px;
  padding: 10px 40px;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2em;
}

.input:active, .input:focus {
  border: none;
  outline: none;
}
