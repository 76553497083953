@import url('../../../assets/styles/variables.css');

* {
  font-family: var(--primary-font);
}

.titlePastDueDash,
.subtitlePastDueDash {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: var(--new-dashboard-gray);
}

.titlePastDueDash {
  font-size: 24px;
  line-height: 30px;
}

.subtitlePastDueDash {
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.customerName {
  color: #11183F;
  font-weight: 600;
}

.uploadDocument {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0;
}

.uploadDocument > div {
  flex: 1;
  margin: 0 20px;
}

.uploadDocumentTitle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 0.9em;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.uploadDocumentContent {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  padding: 20px;
  background: var(--white-color);
  border-radius: 8px;
}

.top {
  height: 130px;
  background: #1B2752;
  padding: 40px 20px 30px 60px;
}

.top h3 {
  color: var(--white-color);
  font-size: 36px;
}

.subtitlePastDueDashCustomer {
  font-size: 30px;
  font-weight: bold;
  color: #1B2752;
  margin: 0;
  margin-bottom: 30px;
}

.infoCustomer {
  text-align: center;
  margin-bottom: 80px;
}

.infoCustomer h5 {
  font-size: 16px;
  color: var(--bunting-color);
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
}


h5 a {
  font-size: 16px;
  color: var(--new-dashboard-primary);
  text-decoration: none;
}

.paddingContent {
  padding: 40px 40px 40px 60px; 
  background: #fff;
}

.smallMargin {
  margin-bottom: 20px;
}

.imageContainer img {
  width: 100%; 
  max-width: 350px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .uploadDocumentContent {
    flex-direction: column;
  }

  .imageContainer img {
    max-width: 100%;
    height: auto;
  }
}