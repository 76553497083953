@import url('../../../../assets/styles/variables.css');

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "area1 area2"
      "area3 area3";
    gap: 10px;
    padding: 40px 40px 40px 60px;
}

.item1 {
  grid-area: area1;
  height: auto;
}

.item2 {
  grid-area: area2;
  height: auto;
}

.item3 {
  grid-area: area3;
  height: auto;
}