@import url('../../assets/styles/variables.css');

.wrapper {
  width: 100%;
  margin-bottom: 5px;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  width: calc(95% - 10px);
}

.cardItem {
  display: flex;
  min-height: 80px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
}

.cardImage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #334588;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;   
}

.cardImage img {
 width: 35px;
 height: 35px;
 padding: 15px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.cardContent span {
  color: #13141E;
  font-size: 15px;
}

.cardContent h4 {
  color: #334588;
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
}

.dropdownButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  border-radius: 8px;

  width: 90%;
  border: 1px solid #120638;
  min-height: 40px;
  cursor: pointer;
}

.dropdownButton span {
  transition: transform 0.3s;
}

.dropdownButton span.rotate {
  transform: rotate(180deg);
}

.dropdownContainer .checkboxList {
  display: none;
}

.dropdownButton.active {
  border-radius: 8px 8px 0 0;
}

.dropdownContainer .checkboxList.open {
  display: block;
  position: absolute;
  width: calc(90% - 20px);
  max-height: 220px;
  overflow-y: auto; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  background: #fff;
  z-index: 1000;
  left: 20px;
  border: 1px solid #120638;
  border-radius: 0 0 8px 8px;
}

.checkboxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.checkboxContainer input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 15px;
} 

.checkboxContainer label {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
} 

.generalButton {
  white-space: nowrap;
  width: calc(95% - 10px);
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: var(--portal-secondary-color);
  margin: 20px 10px 0 10px;
  cursor: pointer;
  color: #fff;
  margin-left: 0;
}

.bottom {
  margin-top: 50px;
}

.wrapper h5 {
  color: #334588;
  font-size: 13px;
  margin-bottom: 5px;
}

.modalSuccessContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

