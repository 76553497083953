.logoutContainer {

}

.logoutContainer li {
  display: flex;
  align-items: center;
  list-style: none;
  color: var(--dash-sidebar-primary);
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.logoutContainer i svg {
  width: 25px;
  height: 25px;
}