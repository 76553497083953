@import url('../../assets/styles/variables.css');

.wrapper {

}

.wrapper h3 {
  font-size: 18px;
}

.top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}

.wrapper h2 {
  font-size: 20px;
  color: var(--dash-sidebar-secondary);
}

.wrapper h3 {
  font-size: 18px;
  color: var(--dash-sidebar-secondary);
}

.radioItem {
  display: flex;
  padding: 30px 30px 30px 0;
  border-bottom: 1px solid var(--portal-secondary-light-color);
}

.radioItem * {
  font-family: Montserrat;
  text-transform: none;
}

.radioItem input {
  width: 25px;
  height: 25px;
}

.radioItem label {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--bunting-color);
  margin-bottom: 8px;
}

.radioItem label b {
  font-size: 20px;
  font-weight: 700;
  margin-left: 5px;
  color: var(--portal-secondary-color);
}

.radioItem span {
  font-size: 12px;
}

.radioTexts {
  display: flex;
  flex-direction: column;  
  margin-left: 15px;
}

.radioTextsParcelado {
  margin-left: 15px;
}

.radioTextsParcelado label {
  display: block;
}

.radioTextsParcelado label b {
  display: block;
  margin: 10px 0 0 0;
}

.radioItem input[type="radio"] {
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--portal-secondary-color);
  border-radius: 50%;
  background-color: white;
  position: relative;
  vertical-align: middle;
  margin-right: 8px;
  cursor: pointer;
}

.radioItem input[type="radio"]:checked {
  border: 2px solid var(--portal-secondary-color);
}

.radioItem input[type="radio"]:checked:after  {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--portal-secondary-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dueDate .radioTexts {
  justify-content: center;
}

.dueDate .radioItem {
  border: none;
  padding: 20px 20px 20px 0;
}

.generalButton {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: var(--portal-secondary-color);
  margin: 20px 10px 0 10px;
  cursor: pointer;
  color: #fff;
}

.close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: var(--portal-secondary-light-color);
}

.close svg {
  fill: var(--portal-dark-gray-color);
  width: 25px;
  height: 25px;
}