.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownButton {
  width: 145px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  padding: 15px 10px;
  border: none;
  height: auto;
  display: inline;
  background: var(--portal-secondary-color);
  margin: 20px 10px 0 10px;
  cursor: pointer;
  color: #fff;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid var(--portal-secondary-color);
  width: 145px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin: 10px;
  border-radius: 5px;
  transform: translateY(-10px);
  cursor: pointer;
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownContent a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdownContent {display: block;}

.arrowDown, .arrowUp {
  display: inline-block;
  transition: transform 0.3s ease;
  margin-left: 5px;
}

.arrowUp {
  transform: rotate(180deg);
}

