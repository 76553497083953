@import url('../../../assets/styles/variables.css');

.image {
  display: flex;
  justify-content: center;
  margin: 50px auto;
}

.image img {
  max-width: 70%;
}


