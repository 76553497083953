@import url('../../assets/styles/variables.css');

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: var(--portal-header-primary);
}

.wrapper img {
  padding: 30px 20px;
}

.wrapper img.effect {
  padding: 0 0;
  transform: translateY(-40px);
}

.wrapper .companyLogo {
  max-width: 200px;
}