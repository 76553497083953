.items {
  display: flex;
  justify-content: space-between;
}

.items > div {
  width: 33.33%;
  margin-right: 20px;
}

.items > div:last-child {
  margin-right: 0;
}

.cardContent {
  display: flex;
  align-items: center;
}

.cardContent span {
  color: #828282;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
}

.container {
  padding: 0 45px;
}

.containerTop {
  margin-top: 40px;
}