.noOfferContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  background-color: var(--portal-primary-color);
}

.top {
  min-height: 70vh;  
  padding: 0 90px;
}

.footer {
  display: block;;
  min-height: 30vh;
  width: 100%;
  background-image: url('../../../assets/images/confetti.svg');
  background-repeat: no-repeat;
  background-position: right;
}

.icon {
  display: block;
  width: 135px;
  height: 100px;
  margin-top: 150px;
}

@media only screen and (min-width: 1100px) {
  .top {
    margin: 0 auto;
  }
}
